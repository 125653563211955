import * as React from 'react'
import {focusElement, addCircularTabFocus} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Tickets} from '../../tickets'
import {AccessibilityMode} from '../../accessibility-mode'
import {TicketDescription} from '../../ticket-description'
import {SelectedPlace} from '../../../../../types/seating'
import s from './sidebar.scss'
import {SidebarProps} from '.'

export const Sidebar = ({selectedSeats, showAccessibilityMode, onRemoveClick, onCheckoutClick}: SidebarProps) => {
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<SelectedPlace>(null)

  React.useEffect(() => {
    focusElement({selector: `[data-hook="${DH.EVENT_INFO}"]`, canFocus: true})
    addCircularTabFocus(`[data-hook="${DH.CLOSE_BUTTON}"]`, `[data-hook="${DH.ACCESSIBILITY_MODE_BUTTON}"]`)
  }, [showAccessibilityMode])

  return (
    <div className={s.sidebar}>
      {showAccessibilityMode ? <AccessibilityMode /> : null}
      {!showAccessibilityMode ? (
        <Tickets
          selectedPlaces={selectedSeats}
          showTickets={true}
          onRemoveClick={onRemoveClick}
          onCheckoutClick={onCheckoutClick}
          onTicketMoreInfoClick={setSelectedSeatForDescription}
        />
      ) : null}
      <TicketDescription
        selectedSeatForDescription={selectedSeatForDescription}
        onClose={() => setSelectedSeatForDescription(null)}
      />
    </div>
  )
}
