import * as React from 'react'
import {FloatingDropdown} from 'wix-ui-tpa/FloatingDropdown'
import {classes} from './styled-dropdown.st.css'
import {StyledDropdownProps} from '.'

export const StyledFloatingDropdown = ({options, ariaLabel, label, value, dataHook, onChange}: StyledDropdownProps) => (
  <FloatingDropdown
    data-hook={dataHook}
    label={label}
    value={value}
    aria-label={ariaLabel}
    className={classes.dropdown}
    options={options}
    onChange={onChange}
  />
)
