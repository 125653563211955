import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SlideInModal} from '../../slide-in-modal'
import {TicketsListContainer} from '../tickets-list-container'
import {Ticket} from '../ticket'
import {SelectedPlace} from '../../../../types/seating'
import {getSeatWithTicketInfo} from '../../../../selectors/seating'
import {getSelectedTicketQuantity} from '../../../../selectors/selected-tickets'
import {Header} from './header'
import {Filters} from './filters'
import {classes} from './accessibility-mode.st.css'
import {Footer} from './footer'
import {AccessibilityModeProps} from '.'

export const AccessibilityMode = ({
  showAccessibilityMode,
  tickets,
  plan,
  event,
  selectedTickets,
  selectedZone,
  filteredCategories,
  setShowAccessibilityMode,
  selectedPlaces,
  selectPlace,
  selectTicket,
  unselectPlace,
  unselectTicket,
}: AccessibilityModeProps) => {
  const {t} = useTranslation()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.BACK), canFocus: true})
    addCircularTabFocus(hookToAttributeSelector(DH.CLOSE_BUTTON), hookToAttributeSelector(DH.ACCESSIBILITY_MODE_END))
  }, [showAccessibilityMode])

  const seats: SelectedPlace[] = filteredCategories
    .flatMap(category =>
      category.places
        .filter(seat => seat.capacity > 0 && (selectedZone ? seat.id.split('-')[1] === selectedZone : true))
        .map(seat => getSeatWithTicketInfo({plan, tickets, event, seat, categoryId: category.id, t})),
    )
    .sort((a, b) => b.capacity - a.capacity)

  const seatsCount = seats.length

  const handlePlaceClick = (clickedPlace: SelectedPlace, selected: boolean) => {
    const ticketId = clickedPlace.ticketId
    const placeId = clickedPlace.id

    if (selected) {
      unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1, placeId})
      unselectPlace(placeId)
    } else {
      selectPlace(clickedPlace)
      selectTicket({
        ticketId,
        count: getSelectedTicketQuantity(selectedTickets, ticketId) + 1,
        placeId,
      })
    }
  }

  return (
    <SlideInModal
      show={showAccessibilityMode}
      backButtonText={t('seatings_closeTicketList')}
      onBackClick={() => setShowAccessibilityMode(false)}
    >
      <Header />
      <Filters />
      <Text className={classes.seatsInfo}>
        {seatsCount === 1 ? t('seatings_ticket_total') : t('seatings_tickets_total', {count: seats.length})}
      </Text>
      <TicketsListContainer>
        {seats.map((seat, index) => {
          const selected = selectedPlaces.some(selectedPlace => selectedPlace.id === seat.id)

          return (
            <Ticket
              showAccessibilityMode={showAccessibilityMode}
              key={seat.id}
              lastTicket={index === seats.length - 1}
              place={seat}
              onClick={clickedPlace => handlePlaceClick(clickedPlace, selected)}
              selected={selected}
            />
          )
        })}
      </TicketsListContainer>
      <Footer />
    </SlideInModal>
  )
}
