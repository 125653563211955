import {getFormattedPrice} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {calculateSubPrice} from '../../../../../services/payment'
import {TableRow} from '../../../../table-row'
import {ItemProps} from '.'

const getPriceText = (price: wix.events.Money, t: TFunction) =>
  Number(price.amount) ? getFormattedPrice(Number(price.amount), price.currency) : t('ticketPrice.free')

export const Item: React.FC<ItemProps> = ({item, t}) => {
  const price = getPriceText(
    {
      amount: calculateSubPrice(Number(item.price.amount), item.quantity).toString(),
      currency: item.price.currency,
    },
    t,
  )

  const config = {
    dataHook: DH.ORDER_SUCCESS_SUMMARY,
    itemKey: item.id,
    columns: [
      {content: item.name, dataHook: DH.TICKET_NAME},
      {content: getPriceText(item.price, t), dataHook: DH.TICKET_PRICE},
      {content: item.quantity, dataHook: DH.TICKET_QUANTITY},
      {content: price, dataHook: DH.TICKET_TOTAL_PRICE},
    ],
  }

  return (
    <div data-hook={DH.ORDER_SUCCESS_SUMMARY_WRAPPER}>
      <TableRow config={config} />
    </div>
  )
}
