import React from 'react'
import {Ticket} from '../ticket'
import s from './columns.scss'
import {ColumnsProps} from '.'

export const Columns: React.FC<ColumnsProps> = ({
  tickets,
  selectedTickets,
  selectTicket,
  unselectTicket,
  t,
  reserveTicketsPending,
}) => {
  const onQuantityPickerChange = ({ticketId, count}: {ticketId: string; count: number}) => {
    if (reserveTicketsPending) {
      return
    }

    const currentCount = selectedTickets[ticketId] ?? 0
    currentCount < count ? selectTicket({ticketId, count}) : unselectTicket({ticketId, count})
  }

  return (
    <ul className={s.list} role="rowgroup">
      {tickets.map(ticket => (
        <li key={ticket.id}>
          <Ticket t={t} ticket={ticket} onChange={onQuantityPickerChange} />
        </li>
      ))}
    </ul>
  )
}
