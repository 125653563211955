import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import s from './summary-container.scss'
import {SummaryContainerProps} from '.'

export const SummaryContainer: React.FC<SummaryContainerProps> = ({inTickets, children}) => {
  const {isMobile} = useEnvironment()
  const mobileAndInTickets = isMobile && inTickets

  return <div className={classNames(s.container, {[s.mobileTicketsContainer]: mobileAndInTickets})}>{children}</div>
}
