import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {InvoiceBreakdown} from '../../invoice-breakdown'
import {Modal} from '../modal'
import {Divider} from '../common/divider'
import {OrderSummaryProps} from './interfaces'
import s from './order-summary.scss'
import {Ticket} from './ticket'
import {Seat} from './seat'

export const OrderSummary = ({eventDate, eventLocation, eventTitle, tickets, t, seatingPlan}: OrderSummaryProps) => {
  const seatIds = tickets.flatMap(ticket => ticket.seatIds)

  return (
    <Modal
      lastChildSelector={hookToAttributeSelector(DH.CLOSE_BUTTON)}
      firstChildSelector={hookToAttributeSelector(DH.CLOSE_BUTTON)}
      dataHook={DH.ORDER_SUMMARY_MODAL}
      returnFocusElement={hookToAttributeSelector(DH.SHOW_SUMMARY_BUTTON)}
    >
      <div className={s.title}>{t('mobile.couponForm.title')}</div>
      <div className={s.eventTitle}>{eventTitle}</div>
      <div className={s.secondaryText}>{eventDate}</div>
      <div className={s.secondaryText}>{eventLocation}</div>
      <Divider />
      <div>
        {seatIds.length
          ? seatIds.map(seatId => (
              <>
                <Seat
                  key={seatId}
                  seatId={seatId}
                  seatingPlan={seatingPlan}
                  ticket={tickets.filter(ticket => ticket.seatIds.includes(seatId))[0]}
                />
                <Divider />
              </>
            ))
          : tickets.map(ticket => <Ticket key={ticket.id} ticket={ticket} t={t} />)}
      </div>
      <InvoiceBreakdown t={t} divider={<Divider />} />
    </Modal>
  )
}
