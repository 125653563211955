import * as React from 'react'
import classNames from 'classnames'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {TicketInfo} from '../ticket-info'
import {classes} from './ticket.st.css'
import {TicketAction} from './ticket-action'
import {TicketProps} from '.'

export const Ticket = ({
  place,
  selected,
  lastTicket,
  inDescription,
  showAccessibilityMode,
  onRemoveClick,
  onClick,
  onTicketMoreInfoClick,
}: TicketProps) => {
  const area = place?.elementType === Type.AREA

  const handleRemove = () => onRemoveClick(place.id, place.ticketId)

  const handleKeyDown: React.KeyboardEventHandler<HTMLLIElement> = event => {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  const handleClick = () => onClick?.(place)

  return (
    <li
      className={classNames(classes.ticket, {[classes.lastTicket]: lastTicket, [classes.selected]: selected})}
      onClick={area ? undefined : handleClick}
      onKeyDown={area ? undefined : handleKeyDown}
      tabIndex={0}
      data-hook={`ticket-${place?.id}`}
    >
      <div className={classes.color} style={{backgroundColor: place?.color}} />
      <TicketInfo place={place} onRemoveClick={onRemoveClick ? handleRemove : undefined} selected={selected} />
      <TicketAction
        area={area}
        ticketDescription={place?.ticketDescription}
        selected={selected}
        showAccessibilityMode={showAccessibilityMode}
        inDescription={inDescription}
        onClick={() => (showAccessibilityMode ? handleClick() : onTicketMoreInfoClick(place))}
      />
    </li>
  )
}
