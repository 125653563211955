import * as React from 'react'
import {Toast, TOAST_SKIN} from 'wix-ui-tpa/Toast'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Plan} from '../plan'
import s from './desktop.scss'
import {Sidebar} from './sidebar'
import {DesktopProps} from '.'

export const Desktop = ({
  selectedPlace,
  selectedPlaces,
  plan,
  onSeatClick,
  onRemoveClick,
  onCheckoutClick,
  showAccessibilityMode,
}: DesktopProps) => {
  const {t} = useTranslation()

  return (
    <div className={s.container}>
      <Sidebar selectedSeats={selectedPlaces} onRemoveClick={onRemoveClick} onCheckoutClick={onCheckoutClick} />
      <div className={classNames(s.planContainer, {[s.accessibilityMode]: showAccessibilityMode})}>
        {showAccessibilityMode ? (
          <Toast className={s.toast} skin={TOAST_SKIN.status}>
            {t('seatings_accessibility_toast')}
          </Toast>
        ) : null}
        <Plan selectedSeat={selectedPlace} selectedSeats={selectedPlaces} plan={plan} onSeatClick={onSeatClick} />
      </div>
    </div>
  )
}
