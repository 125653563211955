import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {AccessibilityModeRuntimeProps, AccessibilityModeOwnProps} from './interfaces'
import {AccessibilityMode as Presentation} from './accessibility-mode'

const mapRuntime = ({
  state,
  actions: {setShowAccessibilityMode, selectPlace, selectTicket, unselectPlace, unselectTicket},
}: DetailsPageAppProps): AccessibilityModeRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  plan: state.seating.plan,
  tickets: state.tickets,
  selectedTickets: state.selectedTickets,
  selectedPlaces: state.seating.selectedPlaces,
  event: state.event,
  selectedZone: state.seating.selectedZone,
  filteredCategories: state.seating.filteredCategories,
  setShowAccessibilityMode,
  selectPlace,
  selectTicket,
  unselectPlace,
  unselectTicket,
})

export const AccessibilityMode = connect<AccessibilityModeOwnProps, AccessibilityModeRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
