import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {Summary} from '../summary'
import {TicketInfo} from '../ticket-info'
import {AccessibilityModeButton} from '../accessibility-mode-button'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({selectedPlace, withoutBreakdown, onAddClick, onCloseClick, onCheckoutClick}: FooterProps) => {
  const {isMobile} = useEnvironment()

  return (
    <>
      {isMobile ? null : <AccessibilityModeButton />}
      <div className={s.footer}>
        {selectedPlace ? (
          <TicketInfo place={selectedPlace} onAddClick={onAddClick} onCloseClick={onCloseClick} />
        ) : (
          <Summary withoutBreakdown={withoutBreakdown} onCheckoutClick={onCheckoutClick} />
        )}
      </div>
    </>
  )
}
