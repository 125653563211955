import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {LabelWithValue} from '../../../../common/label-with-value'
import {classes} from './ticket-info-texts.st.css'
import {TicketInfoTextsProps} from '.'

export const TicketInfoTexts = ({
  place: {sector, seat, ticketName, ticketPrice, fees, row, elementType},
}: TicketInfoTextsProps) => {
  const {t} = useTranslation()
  const area = elementType === Type.AREA

  return (
    <>
      <div className={classes.infoContainer}>
        <LabelWithValue labelClassName={classes.label} label={t('seatings_sector')} value={sector} />
        <LabelWithValue
          labelClassName={classes.label}
          label={area ? t('seatings_area') : t('seatings_row')}
          value={row}
        />
        {area ? null : <LabelWithValue labelClassName={classes.label} label={t('seatings_seat')} value={seat} />}
      </div>
      <div className={classes.ticketInfo}>
        <Text className={classes.ticketName}>{ticketName}</Text>
        <Text className={classes.ticketPrice}>{ticketPrice}</Text>
        <Text className={classes.smallText}>{fees}</Text>
      </div>
    </>
  )
}
