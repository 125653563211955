import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../classnames.scss'
import {MembershipOffers} from '../../../event-details/membership-offers'
import {MobileTicket} from '../../../event-details/tickets-picker/ticket'
import {TotalPrice} from '../../../event-details/tickets-picker/total-price'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import s from './mobile-tickets-picker.scss'
import {MobileTicketsPickerProps} from '.'

export class MobileTicketsPicker extends React.Component<MobileTicketsPickerProps> {
  componentDidMount() {
    this.props.resetCouponCode()
    this.props.clearInvoice()
    this.props.clearCheckout()
  }

  componentDidUpdate(prevProps: MobileTicketsPickerProps) {
    const {reservationUnavailable, openCheckoutUnavailable} = this.props
    if (prevProps.reservationUnavailable !== reservationUnavailable && reservationUnavailable) {
      openCheckoutUnavailable()
    }
  }

  render() {
    const {title, buttonStyle, tickets, selectTicket, t, checkoutButtonDisabled, showPaidPlans, checkout} = this.props
    return (
      <div data-hook={DH.TICKETS_PICKER} className={classNames(c.evBackgroundColor, s.mobileCheckoutWrapper)}>
        {showPaidPlans && <MembershipOffers />}
        <div className={classNames(c.evSubtitlesFont, c.evSubtitlesColor, s.mobileCheckoutHeader)}>{title}</div>
        <div className={classNames(c.evTicketDividerColor, s.mobileCheckoutDivider)} />
        {tickets.map(ticket => (
          <MobileTicket t={t} onChange={selectTicket} ticket={ticket} key={ticket.id} />
        ))}
        <TotalPrice t={t} />
        <RegistrationButtonSkin
          secondary={true}
          dataHook={DH.CHECKOUT}
          minWidth="100%"
          disabled={checkoutButtonDisabled}
          buttonStyle={buttonStyle}
          onClick={() => checkout()}
          text={t('checkout')}
        />
      </div>
    )
  }
}
