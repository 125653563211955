import {getFormattedPrice, saleStartedAndHasTickets} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {calculateSubPrice} from '../../../../services/payment'
import c from '../../../classnames.scss'
import {TableRow} from '../../../table-row'
import {FixedHeightColumn} from './fixed-height-column'
import {Price} from './price'
import {Quantity} from './quantity'
import {TicketNameColumn} from './ticket-name-column'
import s from './ticket.scss'
import {TotalColumn} from './total-column'
import {TicketProps} from '.'

export const Ticket: React.FC<TicketProps> = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  t,
  isMobile,
  maxTicketsPerReservation,
  saleScheduled,
  saleStarted,
  saleEnded,
  shouldTicketLimitTooltipShow,
}) => {
  const {id, limitPerCheckout, price} = ticket
  const total = getFormattedPrice(calculateSubPrice(Number(price.amount), selectedQuantity), price.currency)

  return (
    <TableRow
      config={{
        itemKey: id,
        responsiveHideColumns: [1],
        addDivider: true,
        className: classNames(s.row, c.evTextFont, c.evTextColor),
        dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
        dataHook: DH.TICKET,
        columns: [
          {
            className: s.nameColumn,
            content: (
              <div className={s.nameContainer}>
                <TicketNameColumn t={t} ticket={ticket} saleStarted={saleStarted} />
              </div>
            ),
          },
          {
            content: (
              <FixedHeightColumn ticketDefId={id}>
                <Price ticket={ticket} />
              </FixedHeightColumn>
            ),
          },
          {
            content: saleStartedAndHasTickets(ticket) && (
              <FixedHeightColumn ticketDefId={id}>
                <Tooltip
                  className={s.tooltip}
                  disabled={!shouldTicketLimitTooltipShow}
                  shown={shouldTicketLimitTooltipShow}
                  minWidth={140}
                  content={
                    maxTicketsPerReservation === 1
                      ? t('ticketLimitPerReservationReached_singular')
                      : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
                  }
                >
                  <Quantity
                    selectedQuantity={selectedQuantity}
                    availableTicketsQuantity={availableTicketsQuantity}
                    limit={limitPerCheckout}
                    disabled={!limitPerCheckout || registrationClosed}
                    onChange={onChange}
                    id={id}
                    t={t}
                    isMobile={isMobile}
                  />
                </Tooltip>
              </FixedHeightColumn>
            ),
            className: s.overflow,
          },
          {
            content: (
              <FixedHeightColumn className={s.total} ticketDefId={id}>
                <TotalColumn t={t} total={total} ticket={ticket} />
              </FixedHeightColumn>
            ),
          },
        ],
      }}
    />
  )
}
