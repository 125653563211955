import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../classnames.scss'
import {TableRow, TableRowType} from '../../../table-row'
import s from './header.scss'
import {HeaderProps} from '.'

export const Header: React.FC<HeaderProps> = ({title, t}) => {
  const primaryColumnClassNames = classNames(s.column, c.evSubtitlesFont, c.evSubtitlesColor)
  const secondaryColumnClassNames = classNames(s.column, c.evTextFont, c.evTextColor)

  return (
    <div role="rowgroup">
      <TableRow
        config={{
          columns: [
            {content: title, className: primaryColumnClassNames, dataHook: DH.TICKETS_PICKER_HEADER_TITLE},
            {
              content: t('ticketsThankYou.price'),
              className: secondaryColumnClassNames,
              dataHook: DH.TICKETS_PICKER_HEADER_PRICE,
            },
            {
              content: t('ticketsThankYou.quantity'),
              className: secondaryColumnClassNames,
              dataHook: DH.TICKETS_PICKER_HEADER_QUANTITY,
              ariaLabel: t('a11y.quantity'),
            },
            {
              content: t('ticketsThankYou.subTotal'),
              className: secondaryColumnClassNames,
              dataHook: DH.TICKETS_PICKER_HEADER_TOTAL,
            },
          ],
          itemKey: DH.TICKETS_PICKER_TITLES,
          dataHook: DH.TICKETS_PICKER_TITLES,
          addDivider: true,
          type: TableRowType.header,
          responsiveHideColumns: [1],
          dividerClassName: classNames(s.divider, c.evTicketDividerColor, c.evTicketDividerSize),
        }}
      />
    </div>
  )
}
