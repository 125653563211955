import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {TicketInfoRuntimeProps, TicketInfoOwnProps} from './interfaces'
import {TicketInfo as Presentation} from './ticket-info'

const mapRuntime = ({
  state,
  actions: {selectTicket, selectPlace, unselectPlace, unselectTicket},
}: DetailsPageAppProps): TicketInfoRuntimeProps => ({
  selectedTickets: state.selectedTickets,
  showAccessibilityMode: state.seating.showAccessibilityMode,
  selectPlace,
  selectTicket,
  unselectPlace,
  unselectTicket,
})

export const TicketInfo = connect<TicketInfoOwnProps, TicketInfoRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
