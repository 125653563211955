import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {CloseButton} from '../close-button'
import {classes} from './header.st.css'
import {HeaderProps} from '.'

export const Header = ({title, closeButtonAsButton, className, titleClassName, closeModal}: HeaderProps) => (
  <div className={classNames(classes.header, className)}>
    {title ? <Text className={classNames(classes.title, titleClassName)}>{title}</Text> : null}
    {closeModal ? (
      <CloseButton
        className={classes.headerCloseButton}
        closeButtonAsButton={closeButtonAsButton}
        closeModal={closeModal}
      />
    ) : null}
  </div>
)
