import {Place, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import React, {useState, useCallback} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Modal} from '../modal'
import {getSelectedTicketQuantity} from '../../../selectors/selected-tickets'
import {getCategoryById, getSeatWithTicketInfo, getTicketDefinitionByExternalId} from '../../../selectors/seating'
import {SelectedPlace} from '../../../types/seating'
import {Loader} from './loader'
import {Mobile} from './mobile'
import {Desktop} from './desktop'
import {classes} from './seating-plan.st.css'
import {SeatingPlanProps} from '.'

export const SeatingPlan = ({
  plan,
  tickets,
  selectTicket,
  unselectTicket,
  selectedTickets,
  checkout,
  loading,
  event,
  selectedPlaces,
  selectPlaces,
  unselectPlace,
  showAccessibilityMode,
}: SeatingPlanProps) => {
  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const [selectedPlace, setSelectedPlace] = useState<SelectedPlace>(null)
  const desktop = !mobile

  const handleAddClick = useCallback(
    (amount: number = 1) => {
      const ticketId = selectedPlace.ticketId

      selectPlaces(Array(amount).fill(selectedPlace))
      selectTicket({
        ticketId,
        count: getSelectedTicketQuantity(selectedTickets, ticketId) + amount,
        placeId: selectedPlace.id,
      })
      setSelectedPlace(null)
    },
    [selectPlaces, selectTicket, selectedPlace, selectedTickets],
  )

  React.useEffect(() => {
    if (desktop && selectedPlace) {
      handleAddClick()
    }
  }, [selectedPlace, handleAddClick, desktop])

  const handleCheckout = () => checkout()

  const handleRemove = (id: string, ticketId: string) => {
    unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1, placeId: id})
    unselectPlace(id)
  }

  const onSeatClick = (seat: Place, categoryId: number) => {
    const category = getCategoryById(plan, categoryId)

    if (!category) {
      return null
    }

    const ticketDefinition = getTicketDefinitionByExternalId(tickets, category.externalId)
    const area = seat.elementType === Type.AREA

    if (!area && selectedPlaces.find(selected => selected.id === seat.id)) {
      return handleRemove(seat.id, ticketDefinition.id)
    }

    setSelectedPlace(getSeatWithTicketInfo({plan, event, seat, categoryId, t, tickets}))
  }

  return (
    <Modal
      className={desktop ? classes.desktopModal : undefined}
      closeButtonAsButton={desktop}
      dataHook={DH.SEATINGS_MODAL}
      title={mobile ? t('seatingMapModal_title') : undefined}
      withoutPadding={desktop}
    >
      {loading ? <Loader /> : null}
      {mobile ? (
        <Mobile
          selectedPlace={selectedPlace}
          selectedPlaces={selectedPlaces}
          plan={plan}
          onRemoveClick={handleRemove}
          onCheckoutClick={handleCheckout}
          onSeatClick={onSeatClick}
          onCloseClick={() => setSelectedPlace(null)}
          onAddClick={handleAddClick}
        />
      ) : (
        <Desktop
          selectedPlace={selectedPlace}
          selectedPlaces={selectedPlaces}
          plan={plan}
          onSeatClick={onSeatClick}
          onRemoveClick={handleRemove}
          onCheckoutClick={handleCheckout}
          showAccessibilityMode={showAccessibilityMode}
        />
      )}
    </Modal>
  )
}
