import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import s from './tickets-list-container.scss'

export const TicketsListContainer: React.FC = ({children}) => {
  const {isMobile} = useEnvironment()

  return <ul className={classNames(s.list, {[s.mobileList]: isMobile})}>{children}</ul>
}
