import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../../classnames.scss'
import s from './sale-ends-label.scss'
import {SaleEndsLabelProps} from '.'

export const SaleEndsLabel: React.FC<SaleEndsLabelProps> = ({endDate, t, className}) => (
  <div
    className={classNames(c.evTicketAdditionalInfoColor, s.saleEndsLabel, className)}
    role="cell"
    aria-label={`${t('ticketsPicker.saleEnds')}: ${endDate}`}
    data-hook={DH.TICKET_SALE_ENDS}
  >
    <div>{t('ticketsPicker.saleEnds')}</div>
    <div>{endDate}</div>
  </div>
)
