import {getCheckoutSummary, getPaidPlanPercentDiscount, getTaxLabel} from '@wix/wix-events-commons-statics'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SummaryDivider} from '../summary/summary-divider'
import s from './invoice-breakdown.scss'
import {PriceItemSize} from './price-item/interfaces'
import {PriceItem} from './price-item'
import {InvoiceBreakdownProps, InvoiceBreakdownSize} from './interfaces'

export const InvoiceBreakdown: React.FC<InvoiceBreakdownProps> = ({
  invoice,
  t,
  containerClass = '',
  divider = <SummaryDivider />,
  size = InvoiceBreakdownSize.small,
}) => {
  const {subtotal, tax, addedFee, total, discount, paidPlanDiscount} = getCheckoutSummary(invoice)
  const big = size === InvoiceBreakdownSize.big
  const textSize: PriceItemSize = big ? PriceItemSize.medium : PriceItemSize.small
  const totalSize: PriceItemSize = big ? PriceItemSize.extraBig : PriceItemSize.big

  return (
    <div className={`${s.container} ${containerClass}`} role="text" aria-live="polite" data-hook={DH.INVOICE_BREAKDOWN}>
      {subtotal && (
        <div>
          <PriceItem price={subtotal} label={t('ticketsPicker.subtotal')} hook={DH.SUBTOTAL} size={textSize} />
          <PriceItem
            price={paidPlanDiscount}
            label={t('ticketsThankYou.paidPlan', {
              discount: getPaidPlanPercentDiscount(invoice),
            })}
            hook={DH.PAID_PLAN_DISCOUNT_PRICE}
            size={textSize}
          />
          <PriceItem price={discount} label={t('ticketsThankYou.coupon')} hook={DH.DISCOUNT_PRICE} size={textSize} />
          <PriceItem price={tax} label={getTaxLabel(invoice)} hook={DH.TAX} size={textSize} />
          <PriceItem price={addedFee} label={t('checkout_serviceFee')} hook={DH.WIX_FEE} size={textSize} />
          {divider}
        </div>
      )}
      <PriceItem price={total} label={t('mobile.ticketsPicker.total')} hook={DH.TOTAL_PRICE} size={totalSize} />
    </div>
  )
}
