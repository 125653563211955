import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Badge} from '../../../../badge'
import {SoldOutLabelProps} from '.'

export const SoldOutLabel: React.FC<SoldOutLabelProps> = ({t, limit, className}) =>
  !limit && (
    <Badge className={className} data-hook={DH.TICKET_SOLD_OUT}>
      {t('mobile.ticketsPicker.soldOut')}
    </Badge>
  )
