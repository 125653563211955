import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import {TableRow, TableRowType} from '../../../table-row'
import {TotalPrice} from '../total-price'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({buttonStyle, t, checkoutButtonDisabled, checkout}: FooterProps) => (
  <div role="rowgroup">
    <TableRow
      config={{
        columns: [
          {
            className: s.totalPrice,
            content: (
              <>
                <TotalPrice t={t} />
                <RegistrationButtonSkin
                  secondary={true}
                  minWidth="100%"
                  minHeight="48px"
                  disabled={checkoutButtonDisabled}
                  buttonStyle={buttonStyle}
                  onClick={() => checkout()}
                  dataHook={DH.RESERVE_TICKETS_BUTTON}
                  text={t('checkout')}
                />
              </>
            ),
          },
        ],
        itemKey: DH.TICKETS_PICKER_FOOTER,
        type: TableRowType.large,
        className: s.totalPriceRow,
      }}
    />
  </div>
)
