import {getFormattedPrice} from '@wix/wix-events-commons-statics'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {calculateSubPrice} from '../../../../../services/payment'
import {InvoiceBreakdown} from '../../../../invoice-breakdown'
import {Divider} from '../../../common/divider'
import s from './invoice.scss'
import {InvoiceProps} from './index'

export class Invoice extends React.PureComponent<InvoiceProps> {
  getPriceText = (price: wix.events.Money) =>
    Number(price.amount) ? getFormattedPrice(Number(price.amount), price.currency) : this.props.t('ticketPrice.free')

  getTicketAriaLabel = (item: wix.events.ticketing.Item, priceText: string) => {
    const {t} = this.props

    const name = `${t('ticketsThankYou.ticketName')}: ${item.name}`
    const price = t('mobile.thanks.price', {price: this.getPriceText(item.price)})
    const qty = t('mobile.thanks.quantity', {quantity: item.quantity})
    const total = `${t('mobile.thanks.total')} ${priceText}`

    return `${name}, ${price}, ${qty}, ${total}`
  }

  render() {
    const {t, invoice} = this.props

    return (
      <div className="evTextFont formLabelsColor">
        {invoice.items.map(item => {
          const price = this.getPriceText({
            amount: calculateSubPrice(Number(item.price.amount), item.quantity).toString(),
            currency: item.price.currency,
          })

          return (
            <React.Fragment key={item.id}>
              <div
                className={s.container}
                key={item.id}
                data-hook={DH.ORDER_SUCCESS_SUMMARY}
                aria-label={this.getTicketAriaLabel(item, price)}
              >
                <div className={s.row}>{item.name}</div>
                <div className={s.row}>{t('mobile.thanks.quantity', {quantity: item.quantity})}</div>
                <div className={s.row}>
                  <div className={s.rowLeft}>{t('mobile.thanks.price', {price: this.getPriceText(item.price)})}</div>
                  <div className={s.rowRight}>{price}</div>
                </div>
              </div>
              <Divider />
            </React.Fragment>
          )
        })}
        <InvoiceBreakdown t={t} containerClass={s.container} />
      </div>
    )
  }
}
