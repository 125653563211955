import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import LockFill from 'wix-ui-icons-common/on-stage/LockFill'
import {SummaryContainer} from '../../summary-container'
import {InnerSummary} from '../../inner-summary'
import {classes} from './footer.st.css'
import {FooterProps} from '.'

export const Footer = ({
  selectedPlaces,
  tickets,
  event,
  paidPlan,
  selectedTickets,
  setShowAccessibilityMode,
}: FooterProps) => {
  const {t} = useTranslation()
  const selectedPlacesCount = selectedPlaces.length

  return (
    <SummaryContainer>
      <InnerSummary
        invoice={getPreliminaryInvoice(tickets, selectedTickets, getTaxConfig(event), paidPlan)}
        selectedTickets={selectedPlacesCount}
        withoutBreakdown
      />
      <Button
        prefixIcon={<LockFill />}
        data-hook={DH.VIEW_BASKET}
        upgrade
        className={classes.button}
        onClick={() => setShowAccessibilityMode(false)}
      >
        {t('seatings_viewBasket')}
      </Button>
      <div data-hook={DH.ACCESSIBILITY_MODE_END} tabIndex={0} />
    </SummaryContainer>
  )
}
