import * as React from 'react'
import {StatesButton, BUTTON_STATES} from 'wix-ui-tpa/StatesButton'
import {Counter} from 'wix-ui-tpa/Counter'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/build/es/types'
import SelectFill from 'wix-ui-icons-common/on-stage/SelectFill'
import {getSelectedTicketQuantity} from '../../../../selectors/selected-tickets'
import {classes} from './ticket-info.st.css'
import {TicketInfoTexts} from './ticket-info-texts'
import {TicketInfoProps} from '.'

export const TicketInfo = ({
  place,
  selected,
  showAccessibilityMode,
  onAddClick,
  onCloseClick,
  onRemoveClick,
  selectPlace,
  selectTicket,
  selectedTickets,
  unselectPlace,
  unselectTicket,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  const ticketId = place?.ticketId
  const selectedTicketQuantity = getSelectedTicketQuantity(selectedTickets, ticketId)
  const [successButtonState, setSuccessButtonState] = React.useState(BUTTON_STATES.IDLE)
  const [amount, setAmount] = React.useState(showAccessibilityMode ? selectedTicketQuantity : 1)

  if (!place) {
    return null
  }

  const {elementType, capacity} = place
  const area = elementType === Type.AREA

  const handleStatesButtonClick = async () => {
    setTimeout(() => {
      onAddClick(amount)
    }, 800)
    setSuccessButtonState(BUTTON_STATES.SUCCESS)
  }

  const handleCounterChange = (value: string) => {
    if (showAccessibilityMode) {
      const placeId = place.id

      if (amount > Number(value)) {
        unselectTicket({ticketId, count: selectedTicketQuantity - 1, placeId})
        unselectPlace(placeId)
      } else {
        selectPlace(place)
        selectTicket({
          ticketId,
          count: selectedTicketQuantity + 1,
          placeId,
        })
      }
    }

    setAmount(Number(value))
  }

  return (
    <div className={classes.container} aria-hidden={true}>
      {selected ? <SelectFill className={classes.checkbox} /> : null}
      {onCloseClick ? (
        <IconButton className={classes.headerButton} icon={<CloseSmall />} onClick={onCloseClick} />
      ) : null}
      {onRemoveClick ? (
        <IconButton
          data-hook={DH.REMOVE_TICKET}
          className={classes.headerButton}
          icon={<DeleteSmall />}
          onClick={onRemoveClick}
        />
      ) : null}
      <TicketInfoTexts place={place} />
      {area && (onAddClick || showAccessibilityMode) ? (
        <Counter
          className={classes.counter}
          incrementAriaLabel={t('seatings_area_counter_increment')}
          decrementAriaLabel={t('seatings_area_counter_decrement')}
          inputAriaLabel={t('seatings_area_counter_input')}
          errorTooltipMaxWidth={300}
          value={amount}
          min={showAccessibilityMode ? 0 : 1}
          max={capacity}
          onChange={handleCounterChange}
        />
      ) : null}
      {onAddClick ? (
        <StatesButton
          className={classes.addButton}
          data-hook={DH.ADD}
          successContent={t('seatings_addedToOrder')}
          idleContent={t('seatings_add')}
          state={successButtonState}
          onClick={handleStatesButtonClick}
          onNotificationEnd={() => setSuccessButtonState(BUTTON_STATES.IDLE)}
        />
      ) : null}
    </div>
  )
}
