import * as React from 'react'
import classNames from 'classnames'
import ArrowLeftSmall from 'wix-ui-icons-common/ArrowLeftSmall'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TextButton} from 'wix-ui-tpa/TextButton'
import {classes} from './slide-in-modal.st.css'
import {SlideInModalProps} from '.'

export const SlideInModal: React.FC<SlideInModalProps> = ({
  className,
  children,
  show,
  backButtonText,
  fromBottom,
  onBackClick,
}) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  return (
    <div
      className={classNames(classes.container, className, {
        [classes.fromBottom]: fromBottom,
        [classes.show]: show,
        [classes.hide]: !show,
        [classes.desktopContainer]: !isMobile,
      })}
    >
      {onBackClick ? (
        <div className={classes.header}>
          <TextButton
            prefixIcon={<ArrowLeftSmall />}
            className={classes.backButton}
            onClick={onBackClick}
            data-hook={DH.BACK}
          >
            {backButtonText ?? t('back')}
          </TextButton>
        </div>
      ) : null}
      {children}
    </div>
  )
}
