import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Badge} from '../../../../badge'
import {SaleEndedBadgeProps} from '.'

export const SaleEndedBadge: React.FC<SaleEndedBadgeProps> = ({t, className}) => (
  <Badge className={className} data-hook={DH.TICKET_SALE_ENDED}>
    {t('ticketsPicker.saleEndedBadge')}
  </Badge>
)
