import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {MobileOwnProps, MobileRuntimeProps} from './interfaces'
import {Mobile as Presentation} from './mobile'

const mapRuntime = ({state, actions: {setShowAccessibilityMode}}: DetailsPageAppProps): MobileRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  setShowAccessibilityMode,
})

export const Mobile = connect<MobileOwnProps, MobileRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
